import { module } from 'modujs';
import  Swiper from 'swiper';
// import { Navigation } from 'swiper/modules'

export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el;
        this.$slider = this.$('slider')[0]
    }

    init() {
        // Swiper.use([Navigation])
        
        this.options = {
            speed: 400,
            slidesPerView: "auto",
           
            navigation: {
            nextEl: ".arrow-nav__btn.is-next",
            prevEl: ".arrow-nav__btn.is-prev",
          },
          breakpoints: {
            0: {
                centeredSlides: true,
                spaceBetween: 4,
                loop: true,
            },
            650: {
                spaceBetween: 14,
                centeredSlides: false,
                loop: false,
            }


          }
        }

        this.swiper = new Swiper(this.$slider, this.options);
    }   
}