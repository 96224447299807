import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            mouseenter: {
                'sublist-toggler': 'onMouseEnter',
                'sublist': 'onMouseEnter'
            },
            mouseleave: {
                'sublist-toggler': 'onMouseLeave',
                'sublist': 'onMouseLeave'
            }
        }
    }

    init() {
        this.$sublists = this.$('sublist')
        this.$sublistTogglers = this.$('sublist-toggler')
    }

    onMouseEnter(e) {
        const id = e.curTarget.dataset.id

        this.clearItemsState()

        this.$sublists.forEach((item) => {
            if(item.dataset.id == id) {
                item.classList.add('is-active')
            }
        })

        this.$sublistTogglers.forEach((item) => {
            if(item.dataset.id == id) item.classList.add('is-active')
        })

        // Close menu overlay
        //this.call('close', null, 'Menu')
    }

    onMouseLeave(e) {
        this.clearItemsState()
    }

    clearItemsState() {
        this.$sublists.forEach((item) => {
            item.classList.remove('is-active')
        })
        this.$sublistTogglers.forEach((item) => {
            item.classList.remove('is-active')
        })
    }

    destroy() {
        super.destroy()
    }
}
