import { module } from 'modujs';

import  Swiper  from 'swiper';
import { Navigation } from 'swiper/modules'


export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

Swiper.use([Navigation])
        const el = this.el


        this.options = {
            speed: 400,
            slidesPerView: "auto",
            wrapperClass: "case-solution__cases-list",
            slideClass: "case-solution__cases-img",
            navigation: {
                nextEl:  el.querySelector(".arrow-nav__btn.is-next"),
                prevEl:  el.querySelector(".arrow-nav__btn.is-prev"),
              },
            }

        this.swiper = new Swiper(el.querySelector('.case-solution__cases-collection'), this.options);
    }
}
