import  Swiper from 'swiper';
import { module } from 'modujs';
import { Autoplay } from 'swiper/modules'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const el = this.el

        Swiper.use([Autoplay])

        this.options = {
        
            speed: 400,
            direction: 'vertical',
            slidesPerView: 1,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
              },
              rewind: true,
    
            }

        this.swiper = new Swiper(el, this.options);
    }
}
