import { module } from 'modujs';
import modularLoad from 'modularload';

export default class extends module {
    constructor(m) {
        super(m)
    }

    init() {
        this.load = new modularLoad({
            enterDelay: 500,
            exitDelay: 0,
            transitions: {
            }
        })
        
        this.load.on('loading', (transition, oldContainer) => {
            window.Webflow.destroy()
        })

        this.load.on('loaded', (transition, oldContainer, newContainer) => {

            const templateSlug = newContainer.dataset.template

            if(templateSlug == 'cases') {
                FsAttributes.cmsfilter.init()
            }

            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
         
        })
        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            window.Webflow.ready()
            window.Webflow.require('ix2').init()
            window.Webflow.require('lottie').lottie.play()
        })
    }
}